<template>
  <page>
    <template slot="headerRight">
      <el-button
        v-auth="'group:add'"
        @click="
          addVisible = true
          currentItem = null
        "
        type="primary"
      >
        添加分组
      </el-button>
      <!-- <el-button @click="delHandler(false)" :disabled="!multipleSelection.length" type="danger"> 批量删除 </el-button> -->
    </template>

    <template slot="headerLeft">
      <el-form inline :model="params" ref="filtersForm">
        <el-form-item label="分组名称" prop="name">
          <el-input v-model="params.name" placeholder="分组名称"></el-input>
        </el-form-item>
      </el-form>
    </template>

    <div class="table-wrap">
      <el-table
        @selection-change="handleSelectionChange"
        :data="tableData"
        v-loading="loading"
        height="100%"
      >
        <!-- <el-table-column  type="selection" width="55" /> -->
        <el-table-column show-overflow-tooltip prop="id" label="分组ID" />
        <el-table-column show-overflow-tooltip prop="name" label="分组名称" />
        <el-table-column
          show-overflow-tooltip
          prop="description"
          label="分组描述"
        />
        <el-table-column label="操作">
          <template slot-scope="{ row }">
            <el-button
              v-auth="'group:update'"
              @click="editHandler(row)"
              type="text"
              size="mini"
              >编辑</el-button
            >
            <el-button
              v-auth="'group:delete'"
              @click="delHandler(row.id)"
              type="text"
              size="mini"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <pagination
      slot="pagination"
      :totalCount="totalCount"
      :page-size.sync="params.limit"
      :page.sync="params.page"
      @change="getData"
    />

    <AddModal
      v-if="addVisible"
      @getData="getData(true)"
      :visible.sync="addVisible"
      :current-item="currentItem"
    />
  </page>
</template>

<script>
import watchParamsGetData from '@/mixins/watchParamsGetData'
import AddModal from './components/AddModal.vue'
export default {
  mixins: [watchParamsGetData],
  data() {
    return {
      loading: false,
      visible: false,
      addVisible: false,
      currentItem: null,
      tableData: [],
      groupList: [],
      multipleSelection: [],
      params: {
        limit: 20,
        page: 1,
        name: ''
      },
      totalCount: 0
    }
  },
  created() {
    this.getData()
  },
  components: {
    AddModal
  },
  methods: {
    async getData(query) {
      try {
        this.loading = true
        let params = { ...this.params }
        if (query) {
          params.limit = 20
          params.page = 1
        }
        const res = await this.$api.groupManage.getList(params)
        if (res.groupManageList) {
          this.tableData = res.groupManageList.list
        }
        this.totalCount = res.groupManageList.total
      } catch (err) {
      } finally {
        this.loading = false
      }
    },

    handleSelectionChange(val) {
      this.multipleSelection = val.map(_ => _.id)
    },

    async delHandler(id) {
      try {
        await this.$confirm(
          '删除后，所有供应商该分类下的商品将自动归到未分组下，是否确认删除？',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }
        )

        try {
          this.loading = true
          await this.$api.groupManage.delGroup({
            id: id
          })
          this.$message.success('删除成功')
          this.getData()
        } catch (e) {
          console.log(e)
        } finally {
          this.loading = false
        }
      } catch (err) {
        console.log(err)
      }
    },

    async editHandler(row) {
      try {
        this.currentItem = row
        this.addVisible = true
      } catch (err) {
        console.log(err)
      }
    }
  }
}
</script>

<style scoped lang="scss"></style>
