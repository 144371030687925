<template>
  <el-dialog
    :destroy-on-close="true"
    :close-on-click-modal="false"
    :title="!isCreated ? '编辑' : '新增'"
    :visible.sync="show"
    width="500px"
  >
    <div class="content">
      <el-form
        label-width="100px"
        :model="form"
        size="mini"
        :rules="rules"
        ref="ruleForm"
      >
        <el-form-item label="分组名称" prop="name">
          <el-input
            @input="val => (form.name = forbidSpecial(val))"
            maxlength="20"
            v-model="form.name"
            placeholder="请输入分组名称"
          />
        </el-form-item>
        <el-form-item label="分组描述">
          <el-input
            type="textarea"
            resize="none"
            maxlength="50"
            show-word-limit
            v-model="form.description"
            placeholder="分组描述"
          />
        </el-form-item>
      </el-form>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">取消</el-button>
      <el-button type="primary" :loading="loading" @click="submit"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'

export default {
  mixins: [dialogCommonParams],
  data() {
    return {
      rules: {
        name: [{ required: true, message: '请输入分组名称' }]
      },
      form: {
        name: '',
        description: ''
      },
      loading: false
    }
  },
  created() {
    if (this.currentItem) {
      this.form.name = this.currentItem.name
      this.form.description = this.currentItem.description
    }
  },
  methods: {
    submit() {
      this.$refs.ruleForm.validate(async valid => {
        if (valid) {
          this.loading = true
          try {
            const params = { ...this.form }

            if (!this.isCreated) {
              params.id = this.currentItem.id
              await this.$api.groupManage.updateGroup(params)
              this.$message.success('编辑成功')
            } else {
              await this.$api.groupManage.saveGroup(params)
              this.$message.success('添加成功')
            }
            this.show = false
            this.$emit('getData')
          } catch (e) {
            console.log(e)
          } finally {
            this.loading = false
          }
        }
      })
    }
  }
}
</script>

<style scoped lang="scss"></style>
